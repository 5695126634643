/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from "react";
import "../css/calander.css";
import car from "../assets/img/car.jpg";
import AddReservation from "../components/Calendar/AddReservation";
import { useDispatch, useSelector } from "react-redux";
import { fetchVehicleList } from "../Redux/vehicleSlice";
import { Scheduler } from "@bitnoi.se/react-scheduler";
import { reservationList } from "../Redux/calanderSlice";
import moment from "moment";
import { getReservationById } from "../services/Calendar/reservation";
import { useNavigate } from "react-router-dom";
import "moment/locale/fr";
import { useLoadScript } from "@react-google-maps/api";

moment.locale("fr");
moment.updateLocale("fr", {
  months: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ],
});

const libraries = ["places"];

const Calendar = () => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("Add a Reservation");
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [id, setId] = useState(null);
  const { vehicleList } = useSelector((state) => state?.vehicleSlice);
  const { reservationData } = useSelector((state) => state?.calanderSlice);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyCfC8Izlpa7eb4Rae3EtA5-WcmrHr8BHAA",
    libraries,
  });

  const calanderData = vehicleList
    .filter(
      (vehicle) => vehicle.active === "Active" && vehicle.status === "A louer"
    )
    ?.map((vehicle) => {
      const reservationList = reservationData
        ?.filter((reservation) => {
          return (
            reservation?.general_id?._id === vehicle?._id &&
            reservation?.status !== "Not Confirmed"
          );
        })
        .map((reservation) => ({
          id: reservation?._id,
          startDate: moment(reservation?.start_date)
            .add(1, "days")
            .format("YYYY-MM-DD"),
          endDate: moment(reservation?.return_date)
            .subtract(1, "days")
            .format("YYYY-MM-DD"),
          occupancy: 3600,
          title: `${moment(reservation?.start_date).format(
            "MMM DD"
          )} To ${moment(reservation.return_date).format("MMM DD")}`,
          subtitle: `(${reservation?.customer_id?.firstname} ${reservation?.customer_id?.lastname})`,
          Enable: false,
          bgColor: `${
            reservation?.status === "Not Confirmed"
              ? "rgb(0 207 232 / 28%)"
              : reservation?.status === "Confirmed"
              ? "Green"
              : reservation?.status === "Maintenance"
              ? "Orange"
              : reservation?.status === "Repair"
              ? "Red"
              : "Blue"
          }`,
          color: "white",
        }));

      return {
        id: vehicle.general_id,
        label: {
          icon: car,
          title: `${vehicle?.manufacturer}: ${vehicle?.model} ${vehicle?.declination}`,
          subtitle: vehicle?.license_plate,
        },
        data: reservationList,
      };
    });

  useEffect(() => {
    dispatch(fetchVehicleList());
    dispatch(reservationList());
  }, [dispatch]);

  const addedNewEvent = (array) => {
    array = array.flatMap((item) => {
      const duplicatedItem = { ...item }; // Create a shallow copy of the original object
      duplicatedItem.data = [...item.data]; // Duplicate the data array

      item.data.forEach((innerArray) => {
        const startDate = new Date(innerArray.startDate);
        const endDate = new Date(innerArray.endDate);

        // Adjust startDate by subtracting one day
        startDate.setDate(startDate.getDate() - 1);

        // Adjust endDate by adding one day
        endDate.setDate(endDate.getDate() + 1);

        // Check-in object
        const checkInObj = {
          id: `${innerArray.id}-checkin`,
          checkin: startDate.toISOString().split("T")[0],
          title: "Check In",
          subtitle: innerArray.subtitle,
          Enable: false,
          bgColor: innerArray.endColor
            ? innerArray.endColor
            : innerArray.bgColor,
          color: innerArray.color,
          startDate: endDate.toISOString().split("T")[0],
          endDate: endDate.toISOString().split("T")[0],
        };

        // Check-out object
        const checkOutObj = {
          id: `${innerArray.id}-checkout`,
          checkout: endDate.toISOString().split("T")[0],
          title: "Check Out",
          subtitle: innerArray.subtitle,
          Enable: false,
          bgColor: innerArray.startColor
            ? innerArray.startColor
            : innerArray.bgColor,
          color: innerArray.color,
          startDate: startDate.toISOString().split("T")[0],
          endDate: startDate.toISOString().split("T")[0],
        };
        duplicatedItem.data.push(checkInObj);
        duplicatedItem.data.push(checkOutObj);
      });
      return duplicatedItem;
    });

    return array;
  };

  const handleSearch = (array) => {
    if (!searchTerm) return array;

    // Filter based on title or subtitle
    return array.filter(
      (item) =>
        item.label.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.label.subtitle.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  if (loadError) return <></>;
  if (!isLoaded) return <></>;

  return (
    <div className="content-wrapper">
      <div className="container-fluid flex-grow-1 container-p-y">
        <div className="d-flex justify-content-between align-items-center bg-white py-3 pe-3">
          <div className="mx-3 w-50">
            <input
              type="text"
              className="form-control"
              placeholder="Search by title or subtitle..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <button
            onClick={() => {
              setShow(true);
              setName("Localisation du Client durant la reservation");
              setId("");
            }}
            className="btn add-new btn-primary waves-effect waves-light"
            type="button"
          >
            <span>
              <i className="ti ti-plus me-0 me-sm-1 ti-xs"></i>
              <span className="d-none d-sm-inline-block">
                Ajouter une Réservation
              </span>
            </span>
          </button>
        </div>
        <div className="calender w-100" id="hide-calender-search">
          <Scheduler
            onRangeChange={() => ""}
            data={addedNewEvent(handleSearch(calanderData))}
            onItemClick={(data) => {
              navigate(`/fleet/info/${data.id}`);
            }}
            startDate={new Date()}
            onTileClick={async (data) => {
              if (
                !data.id.includes("checkout") &&
                !data.id.includes("checkin")
              ) {
                await getReservationById(data.id);
                setId(data.id);
                setName("Info de reservation");
                setShow(!show);
              }
            }}
            config={{
              Tooltip: false,
              isFiltersButtonVisible: false,
              includeTakenHoursOnWeekendsInDayView: false,
              filterButtonState: -1,
              zoom: 1,
              lang: "en",
              maxRecordsPerPage: vehicleList?.length,
            }}
          />
        </div>
      </div>

      {show && (
        <AddReservation
          show={show}
          onHide={() => setShow(false)}
          res_id={id}
          setId={setId}
          name={name}
        />
      )}
    </div>
  );
};

export default memo(Calendar);
